<template>
  <div class="flex flex-col">
    <Loader v-if="showSuccess" />
    <div v-else>
      <div class="flex flex-row mt-5">
        <Back>Go back to categories</Back>
      </div>

      <div class="w-screen lg:w-3/4 2xl:w-1/2 mx-auto pt-5 md:pt-10">
        <stack
          :column-min-width="size"
          :gutter-width="gw"
          :gutter-height="gh"
          monitor-images-loaded
        >
          <stack-item
            v-for="collection in photoCollections"
            :key="collection.id"
            style="transition: transform 300ms"
          >
            <router-link
              :to="{
                name: 'photoGrid',
                params: { collectionId: collection.id },
              }"
              class="cursor-pointer"
            >
              <img class="w-" :src="collection.image" />
              <div
                class="text-center flex justify-around items-center"
              >
                <div class="mt-2 text-3xl text-black capitalize font-cg font-semibold">
                  {{ collection.name }}
                  <br />
                  <span class="text-sm">
                    {{ collection.location }}
                  </span>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-4 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </div>
                </div>
              </div>
            </router-link>
          </stack-item>
        </stack>
      </div>
      <!-- <div class="grid grid-cols-1 md:grid-cols-3 gap-10 p-3 md:px-16 md:py-6">
        <div v-for="collection in photoCollections" :key="collection.id">
          <router-link
            :to="{
              name: 'photoGrid',
              params: { collectionId: collection.id },
            }"
          >
            <div class="relative mb-5 p-5">
              <img class="object-cover h-80 w-full" :src="collection.image" />
              <div
                class="text-center left-0 right-0 flex justify-around items-center"
              >
                <h2 class="mt-2 text-3xl text-black font-sans">
                  {{ collection.name }}
                  <br />
                  <span class="text-sm font-sans">
                    {{ collection.location }}
                  </span>
                </h2>
              </div>
            </div>
          </router-link>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Back from "@/components/GoBack"
import Loader from "@/components/ImageLoader"
import { mapGetters } from "vuex"
import { Stack, StackItem } from "vue-stack-grid"

export default {
  name: "categoryDatails",
  components: {
    Back, Stack, StackItem, Loader
  },
  props: {
    id: {
      type: [String, Number],
    },
  },
  data() {
    return {
      categoryId: this.$route.params.categoryId,
      showSuccess: false,
      windowWidth: 0,
      size: 300,
      gw: 100,
      gh: 50
    };
  },
  computed: {
    ...mapGetters(["photoCollections"]),
  },
  async created() {
    await this.$store.dispatch("getCollections", this.categoryId).then(() => {
      this.showSuccess = true;

      setTimeout(() => {
        this.showSuccess = false;
      }, 1000);
    })

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 818) {
        this.size = 200
        this.gw = 100
        this.gh = 10
      }
    }
  },
};
</script>

<style lang="sass" scoped></style>
